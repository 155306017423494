export const ROUTES = {
  HOMEPAGE: '/',
  AUTHENTICATION: {
    SIGN_IN: '/login',
    REGISTER_ORGANIZATION: '/registration/company',
    REGISTER_INDIVIDUAL: '/registration/individual',
    REGISTER_INVITE: '/registration/invite',
    RESET_PASSWORD: '/login',
    SET_NEW_PASSWORD: '/reset-password/set-password',
    LOGOUT: '/logout',
    EMAIL_CONFIRMATION: '/account/email-confirmation',
  },
  START_HERE: '/',
  PARTNER_DIRECTORY: '/partner-directory',
  BLOG: '/blog',
  EVENTS: '/events',
  EFFECTIVE_PARTNERSHIPS: '/',
  ORGANIZATION: {
    HOME: '/organization-registration',
    PHOTO: '/organization-registration/profile-photo',
    BASIC: '/organization-registration/basic-information',
    HQ: '/organization-registration/hq-location',
    USERS: '/organization-registration/organization-users',
    ABOUT: '/organization-registration/about-us',
    CONTACTS: '/organization-registration/contacts',
    SOCIAL_NETWORKS: '/organization-registration/social-networks',
    ORGANIZATION_USERS: '/organization-registration/organization-users',
    LANDING: '/organization-registration/landing',
    ORGANIZATION_SUCCESS: '/organization-success',

  },
  SUCCESS_STORIES: '/success-stories',
  PRE_ENGAGEMENT: {
    HOME: '/pre-engagement',
    EXAMINE: '/pre-engagement/examine/programming/1',
    EXAMINE_BASE: '/pre-engagement/examine',
    PROGRAMMING: '/pre-engagement/programming',
    COMPLIANCE: '/pre-engagement/compliance',
    HUMAN_RESOURCES: '/pre-engagement/human-resources',
    PROGRAM_MANAGEMENT: '/pre-engagement/program-management',
    BUDGET_FINANCE: '/pre-engagement/budget-finance',
    LANDING: '/pre-engagement/landing',
    SUMMARY: '/pre-engagement/summary',
    REPORT: '/pre-engagement/report',
  },
  RESOURCE_LIBRARY: '/resource-library',
  MY_RESOURCE_LIBRARY: '/my-resource-library',
  FAQ: '/faq',
  ABOUT: '/about-us',
  DIRECTORY: '/directory',
  RESET_PASSWORD: '/login',
  SURVEY: '/survey',
  PATHWAYS: {
    HOME: '/pathways/',
    BEGIN: '/pathways/begin/',
    SLUG: '/pathways/',
  },
  USER: {
    SETTINGS: '/user/settings',
  },
  UNSOLICITED_PROPOSALS: {
    MY_PROPOSALS : "/my-submissions",
  }
};

export const EMAIL = {
  ORGANIZATION_DOUBTS: 'IndustryLiaison@usaid.gov',
};

export const PDF = {
  PARTNER_DIRECTORY: '/assets/pdfs/USAID_Partner_Directory_Style_Guide.pdf',
};
