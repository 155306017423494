import { ButtonProps } from '@data/strapi/Common/Types';
import Link from 'next/link';
import React from 'react';
import cn from 'classnames';
import styles from './Button.module.scss';

const Button = ({
  variation = 'primary',
  label,
  labelScreenReaderOnly = false,
  url,
  iconLeft,
  iconRight,
  disabled,
  className,
  style,
  onClick,
  submitButton,
  ...props
}: ButtonProps) => {
  const elClass = cn(
    styles.button,
    styles[`variation__${variation}`],
    "button-translate",
    labelScreenReaderOnly && styles[`sr-only`],
    className
  );

  const isExternal = url && url.indexOf('http') === 0;

  if (!url) {
    return (
      <button
        disabled={disabled ? disabled : undefined}
        className={elClass}
        style={style}
        type={submitButton ? 'submit' : 'button'}
        onClick={onClick}
        {...props}
        aria-label={ label && label !== '' ? label : 'icon' }
      >
        {iconLeft && iconLeft}
        {label && <span>{label}</span>}
        {iconRight && iconRight}
      </button>
    );
  } else {
    return (
      <Link
        href={url ? url : ''}
        target={isExternal ? '_blank' : undefined}
        rel={isExternal ? 'nofollow' : undefined}
        onClick={onClick}
        className={elClass}
        prefetch={false}
        style={style}
        {...props}
        aria-label={ label && label !== '' ? label : 'icon' }
      >
        {iconLeft && iconLeft}
        {label && <span>{label}</span>}
        {iconRight && iconRight}
      </Link>
    );
  }
};

export default Button;
